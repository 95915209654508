/** @format */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import {
  DELETE_USER_CLAUSE,
  FILTER_USER_GROUP,
  GET_USER_LIST,
  NEXT_USER_LIST,
  SEARCH_USER_CLAUSE,
  CHANGE_USER_LIST_PAYLOAD,
  UPDATE_USER,
  DELETE_USER
} from './type.actions';

const buildSearchQuery = userClauses => {
  if (userClauses.length === 0) return '';
  const newClauses = [];
  userClauses.forEach(c => {
    if (c.split(' ').length > 1) {
      newClauses.push(...c.split(' '));
    } else {
      newClauses.push(c);
    }
  });
  return (
    '&' +
    newClauses
      .map((clause, i) => {
        return `search[${i}]=${clause}`;
      })
      .join('&')
  );
};

// User
export const getUserList = createAsyncThunk(GET_USER_LIST, async payload => {
  const searchQuery = buildSearchQuery(payload.userClauses);
  const filterUserGroup =
    payload.userGroup.length > 0
      ? '&' + payload.userGroup.map((group, i) => `roles[${i}]=${group.name}`).join('&')
      : '';
  const filterMarket =
    payload.market.length > 0 ? '&' + payload.market.map((market, i) => `markets[${i}]=${market.id}`).join('&') : '';

  const response = await axiosInstance.get(
    `/users?limit=${payload.limit}&order=${payload.order}&order-direction=${payload.orderDirection}${searchQuery}${filterUserGroup}${filterMarket}`
  );
  return response.data;
});

export const filterUserGroup = createAction(FILTER_USER_GROUP);

export const nextUserList = createAsyncThunk(NEXT_USER_LIST, async payload => {
  const response = await axiosInstance.get(`/users?limit=${payload.limit}&cursor=${payload.cursor}`);
  return response.data;
});

export const updateUserListPayload = createAction(CHANGE_USER_LIST_PAYLOAD);

export const searchUserClause = createAction(SEARCH_USER_CLAUSE);
export const deleteUserClause = createAction(DELETE_USER_CLAUSE);

export const updateUser = createAsyncThunk(UPDATE_USER, async payload => {
  const response = await axiosInstance.put(`users/${payload.uuid}`, payload.data);
  return response.data;
});

export const deleteUser = createAsyncThunk(DELETE_USER, async payload => {
  const response = await axiosInstance.delete(`/users/${payload.uuid}`);
  return response.data;
});
