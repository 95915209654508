/** @format */

import { Box, Chip, IconButton, OutlinedInput } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CloseSmallIcon } from '../Icons';

const InputChips = ({ placeholder, name, value, setdatabyname }) => {
  const [clauses, setClauses] = useState(value);
  const [outlinedInputValue, setOutlinedInputValue] = useState('');

  const handleChange = e => {
    setOutlinedInputValue(e.target.value);
  };

  const handleKeyDown = evt => {
    if (['Return', 'NumpadEnter', 'Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();

      const inputClauses = outlinedInputValue
        .split(',')
        .map(item => item.trim())
        .filter(Boolean); // filter empty string

      if (inputClauses.length > 0) {
        const newClauses = [...clauses, ...inputClauses];
        setClauses(newClauses);
        setOutlinedInputValue('');
        setdatabyname(name, newClauses);
      }
    }
  };

  const handleDelete = clause => () => {
    const newClauses = clauses.filter(item => item !== clause);
    setClauses(newClauses);
    setdatabyname(name, newClauses);
  };

  const isOperator = clause => {
    return ['OR', 'AND'].includes(clause.toUpperCase());
  };

  return (
    <Box className="search-chip light">
      <OutlinedInput
        id="clauses"
        placeholder={placeholder}
        fullWidth
        value={outlinedInputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {clauses.map((clause, i) => {
        return (
          <Chip
            key={i}
            label={!isOperator(clause) ? clause : clause.toUpperCase()}
            title={clause}
            onDelete={handleDelete(clause)}
            deleteIcon={
              <IconButton>
                <CloseSmallIcon color="#E38702" height="8" width="8" />
              </IconButton>
            }
          />
        );
      })}
    </Box>
  );
};

export default InputChips;
