/** @format */

import React, { createContext, useEffect, useReducer, useState } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import { keycloakConfig } from 'src/config';
import Keycloak from 'keycloak-js';
import axios from 'src/utils/axios';
import { ENDPOINTS, USER_PROFILE_API_URL } from 'src/constants';
import { Buffer } from 'buffer';

export let keycloakClient = new Keycloak({
  ...keycloakConfig
});

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  isPictureLoading: false,
  user: null,
  role: null,
  error: null,
  picture: null,
  email: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { user, uuid, isAuthenticated } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        uuid
      };
    }
    case 'LOGIN': {
      const { user, role, user_status, uuid, picture, email } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        isInitialised: true,
        user,
        user_status,
        role: role,
        uuid: uuid,
        picture: picture ? `data:image/png;base64,${Buffer.from(picture, 'binary').toString('base64')}` : null,
        email
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        picture: null
      };
    }
    case 'ON_ERROR': {
      return {
        ...state,
        isInitialised: true,
        isAuthenticated: false,
        user: null,
        error: action.payload.error,
        picture: null
      };
    }
    case 'UPDATE_PROFILE_PICTURE': {
      const { picture, isPictureLoading } = action.payload;
      return {
        ...state,
        picture: picture ? `data:image/png;base64,${Buffer.from(picture, 'binary').toString('base64')}` : null,
        isPictureLoading
      };
    }
    case 'TOGGLE_UPLOADING': {
      return {
        ...state,
        isPictureLoading: !state.isPictureLoading
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = locale => {
    keycloakClient.login({ locale: locale ?? 'en' });
  };

  const logout = () => {
    keycloakClient.logout();
    localStorage.removeItem('idToken');
    localStorage.removeItem('refreshToken');
  };

  const register = locale => {
    keycloakClient.register({ locale: locale ?? 'en' });
  };

  const getUserInfo = async (roles, uuid, locale, email) => {
    try {
      const response = await axios.get(`${ENDPOINTS.GET_USER_INFO_ENDPOINT}${uuid}`);
      const user = response.data;
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          role: roles[0],
          uuid,
          email
        }
      });
      return user;
    } catch (error) {
      // console.log(error);
      dispatch({
        type: 'LOGIN',
        payload: {
          uuid,
          user: { locale: locale, email },
          user_status: error.status
        }
      });
      return error.status;
    }
  };

  const getUserProfilePicture = async () => {
    try {
      dispatch({ type: 'TOGGLE_UPLOADING' });
      const responsePicture = await axios.get(`${USER_PROFILE_API_URL}/api/v1/user/picture`, {
        responseType: 'arraybuffer'
      });
      dispatch({
        type: 'UPDATE_PROFILE_PICTURE',
        payload: {
          picture: responsePicture ? responsePicture.data : null,
          isPictureLoading: false
        }
      });
    } catch (error) {
      dispatch({
        type: 'UPDATE_PROFILE_PICTURE',
        payload: {
          picture: null,
          isPictureLoading: false
        }
      });
    }
  };

  const updateUserProfilePicture = async data => {
    dispatch({ type: 'TOGGLE_UPLOADING' });
    await axios.post(`${USER_PROFILE_API_URL}/api/v1/user/picture`, data, {
      headers: {
        'Content-Type': 'image/png'
      }
    });
    const response = await axios.get(`${USER_PROFILE_API_URL}/api/v1/user/picture`, {
      responseType: 'arraybuffer'
    });
    dispatch({
      type: 'UPDATE_PROFILE_PICTURE',
      payload: {
        picture: response ? response.data : null,
        isPictureLoading: false
      }
    });
  };

  const deleteUserProfilePicture = async () => {
    await axios.delete(`${USER_PROFILE_API_URL}/api/v1/user/picture`);
    dispatch({
      type: 'UPDATE_PROFILE_PICTURE',
      payload: {
        picture: null,
        isPictureLoading: false
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        keycloakClient.onTokenExpired = async () => {
          const refreshed = await keycloakClient.updateToken();
          if (refreshed) {
            axios.defaults.headers.common.Authorization = `Bearer ${keycloakClient.token}`;
          }
        };
        keycloakClient.onAuthRefreshError = () => {
          // console.log(':::onAuthRefreshError');
          window.location.href = '/';
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('refresh');
        };
        keycloakClient.onAuthError = () => {
          // console.log(':::onAuthError');
          window.location.href = '/';
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('refresh');
        };

        keycloakClient.onAuthSuccess = () => {
          // console.log(':::onAuthSuccess');
          // sessionStorage.setItem('token', keycloakClient.token);
          // sessionStorage.setItem('refresh', keycloakClient.refreshToken);
        };
        keycloakClient.onReady = () => {
          // console.log(':::onReady', keycloakClient);
        };
        keycloakClient.onAuthLogout = () => {
          // console.log(':::onAuthLogout');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('refresh');
        };
        keycloakClient.onAuthRefreshSuccess = () => {
          // console.log(':::onAuthRefreshSuccess');
        };

        const tokens = {
          token: null,
          refreshToken: null,
          idToken: null
        };
        const search = window.location.search;
        const params = new URLSearchParams(search);

        const sessionToken = sessionStorage.getItem('token');
        const sessionRefresh = sessionStorage.getItem('refresh');
        if (sessionToken && sessionRefresh) {
          tokens.token = sessionToken;
          tokens.refreshToken = sessionRefresh;
        } else if (params.get('token')) {
          tokens.token = params.get('token');
          tokens.refreshToken = params.get('refreshToken');
          tokens.idToken = params.get('idToken');
          sessionStorage.setItem('token', params.get('token'));
          sessionStorage.setItem('refresh', params.get('refreshToken'));
        }

        const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
        if (isFirefox) {
          await keycloakClient.init({
            onLoad: 'login-required',
            checkLoginIframe: false,
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            redirectUri: window.location.href,
            pkceMethod: 'S256'
          });
        } else if (
          (window.location.href.includes('/vdohub') && tokens.refreshToken) ||
          (tokens.refreshToken && tokens.token)
        ) {
          await keycloakClient.init({
            checkLoginIframe: false,
            token: tokens.token,
            refreshToken: tokens.refreshToken,
            redirectUri: window.location.origin + '/dashboard'
          });
        } else {
          await keycloakClient.init({
            // checkLoginIframe: false,
            onLoad: keycloakClient.authenticated ? 'login-required' : 'check-sso',
            redirectUri: window.location.href,
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
          });
        }

        if (keycloakClient.authenticated) {
          axios.defaults.headers.common.Authorization = `Bearer ${keycloakClient.token}`;
          const keyClockAccessRoles = keycloakClient.resourceAccess[keycloakClient.clientId].roles || [];
          getUserInfo(
            keyClockAccessRoles,
            keycloakClient.subject,
            keycloakClient.tokenParsed.locale,
            keycloakClient.tokenParsed.email
          );
          getUserProfilePicture();
        } else {
          if (isFirefox) keycloakClient.login({ redirectUri: window.location.href });
          else {
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: false,
                user: { locale: keycloakClient?.tokenParsed?.locale },
                uuid: keycloakClient?.subject
              }
            });
            // if (window.location.href !== window.location.origin + '/') {
            // keycloakClient.login({ redirectUri: window.location.href });
            // }
          }
        }
      } catch (err) {
        dispatch({
          type: 'ON_ERROR',
          payload: {
            isAuthenticated: false,
            user: null,
            error: {
              message: 'Error while trying to log in, please try again',
              err
            }
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        getUserInfo,
        register,
        updateUserProfilePicture,
        deleteUserProfilePicture
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
