/** @format */

import React from 'react';
import styled from '@emotion/styled';

export const ScrollAble = ({ children, ...rest }) => {
  const ScrollBox = styled.div`
    position: relative;
    width: 100%;
    height: ${rest => (rest.height ? rest.height : `inherit`)};
    margin-top: ${rest => (rest.marginTop ? rest.marginTop : `inherit`)};
    scroll-behavior: smooth;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 5rem;
    &::-webkit-scrollbar {
      display: none;
    }
    @media screen and (max-width: 899px) {
      height: inherit;
    }
  `;
  return (
    <ScrollBox className="scrollable" {...rest}>
      {children}
    </ScrollBox>
  );
};
