/** @format */

import { Box, Modal, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { LabelSorting } from 'src/components/LabelSorting';
import Loader from 'src/components/Loader';
import { AdminEditorHeader } from 'src/components/PageHeader';
import useAuth from 'src/hooks/useAuth';
import { navigationOpenAction } from 'src/store/action/navigationOpenAction';
import { getTopicListForAdmin } from 'src/store/action/topic.Action';
import TopicItem from './topicItem';
import { getRolesList } from 'src/store/action/editor.Action';
import IconLabelButtons from 'src/components/Buttons';
import { adminTopicsDeleteTopic } from 'src/store/action/adminTopics.Action';
import { flatternTopics } from '../topics';

function findCommonItem(array1, array2) {
  for (let i = 0; i < array1.length; i++) {
    for (let j = 0; j < array2.length; j++) {
      if (array1[i] === array2[j]) {
        return true;
      }
    }
  }
  return false;
}

export default function AdminTopics() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const navigate = useNavigate();
  const checkNavOpen = useSelector(state => state.navigationReducer.navigationOpen);
  const topicList = useSelector(state => state.topicReducer.topicListForAdmin);
  const rolesList = useSelector(state => state.editorReducer.rolesList);
  const idDeleteTopic = useSelector(state => state.adminTopicsReducer.idDeleteTopic);
  const topicClauses = useSelector(state => state.adminTopicsReducer.topicClauses);
  const filter = useSelector(state => state.adminTopicsReducer.filter);
  const [sortBy, setSortBy] = useState({ sortBy: 'created', sort: 'DESC' });
  const [actionMenu, setActionMenu] = useState(null);
  const [filteredTopicList, setFilteredTopicList] = useState(null);
  const adminTopicsScrollableRef = useRef();
  // const flatTopics = flatternTopics(topicList);

  useEffect(() => {
    if (topicList && topicList.length === 0) {
      dispatch(
        getTopicListForAdmin({
          lang: user ? user.locale : 'en'
        })
      );
    }
    let _topicList = [...topicList];
    if (topicClauses.length > 0) {
      _topicList = flatternTopics(topicList)
        .filter(c => {
          const found = topicClauses.find(clause => {
            const search = new RegExp(clause, 'i');
            return search.test(c.name);
          });
          return found;
        })
        .map(item => {
          const parentItemId = item.parents[0];
          const rootItemId = item.rootItem.id;
          if (parentItemId === 0) {
            // Level 1
            return item;
          } else if (parentItemId === rootItemId) {
            // Level 2
            return _topicList.map(c => ({ ...c, children: [item] })).find(r => r.id === rootItemId);
          } else {
            // Level 3
            return _topicList
              .map(c => {
                if (c.children) {
                  const level2Filtered = c.children
                    .filter(c2 => c2.id === parentItemId)
                    .map(c2 => ({ ...c2, children: c2.children.filter(c3 => c3.id === item.id) }));
                  return { ...c, children: level2Filtered };
                } else return { ...c };
              })
              .find(r => r.id === rootItemId);
          }
        });
    } else {
      if (filter.role.length > 0) {
        _topicList = _topicList.filter(c => {
          return findCommonItem(
            c.roles,
            filter.role.map(r => r.id)
          );
        });
      }
      if (filter.brand.length > 0) {
        _topicList = _topicList.filter(c => {
          return filter.brand.find(b => c.brand === b.id);
        });
      }
      if (filter.status !== null) {
        _topicList = _topicList.filter(t => t.published === filter.status);
      }
    }

    setFilteredTopicList(_topicList);
    adminTopicsScrollableRef.current.scroll(0, 0);
  }, [dispatch, topicList, user, topicClauses, filter]);

  useEffect(() => {
    if (rolesList && rolesList.length === 0) {
      dispatch(
        getRolesList({
          lang: user ? user.locale : 'en'
        })
      );
    }
  }, [dispatch, rolesList, user]);

  useEffect(() => {
    if (!user.isAdmin) {
      navigate('/dashboard');
    }

    dispatch(navigationOpenAction(false));
    // eslint-disable-next-line
  }, []);

  return (
    <div
      ref={adminTopicsScrollableRef}
      className={`${checkNavOpen ? `admin-topics-nav-open` : ``} admin-user admin-topics`}>
      <Box className="admin-topics-header">
        <AdminEditorHeader text={t('adminTopics.heading')} />
      </Box>

      <Box marginTop="34px" className={`admin-topics`}>
        <Box className="user-list topic-list extranet-user-list">
          {/* head table */}
          <Box className="heading-column">
            <Box className="name">{t('adminTopics.name')}</Box>
            <Box></Box>
            <Box></Box>
            <Box className="displayName">{t('adminTopics.displayName')}</Box>
            {!checkNavOpen && (
              <>
                <Box className="brand">
                  <LabelSorting label={t('adminTopics.brand')} name="brand" sortBy={sortBy} setSortBy={setSortBy} />
                </Box>
                <Box className="user-group">
                  <LabelSorting
                    label={t('adminTopics.userGroup')}
                    name="userGroup"
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                  />
                </Box>
              </>
            )}
            <Box className="action">{t('adminTopics.actions')}</Box>
          </Box>
          {/* end head table */}
          {filteredTopicList?.length > 0 ? (
            filteredTopicList.map((topic, index) => {
              return (
                <TopicItem
                  key={topic.id + index}
                  topic={topic}
                  level={1}
                  actionMenu={actionMenu}
                  setActionMenu={setActionMenu}
                />
              );
            })
          ) : filteredTopicList === null ? (
            <Loader />
          ) : null}
          {actionMenu && (
            <Box
              sx={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', zIndex: '1' }}
              onClick={() => setActionMenu(null)}
            />
          )}
        </Box>
      </Box>

      <Modal
        open={!!idDeleteTopic}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="delete-modal">
        <Box className="delete-modal-inner">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('adminTopics.deletion.title')}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {t('adminTopics.deletion.description')}
          </Typography>
          <Box sx={{ mt: 4, justifyContent: 'flex-end', display: 'flex', gap: 2 }}>
            <IconLabelButtons
              type="button"
              label={t('adminTopics.deletion.no')}
              className="customButton dark"
              onClick={() => dispatch(adminTopicsDeleteTopic(null))}
            />
            <IconLabelButtons
              type="button"
              label={t('adminTopics.deletion.yes')}
              className="customButton light"
              onClick={() => dispatch(adminTopicsDeleteTopic(null))}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
