/** @format */

export const NAVIGATION_OPEN = 'NAVIGATION_OPEN';
export const DASHBOARD_WIDGET = 'DASHBOARD_WIDGET';
export const DASHBOARD_WIDGET_DATA = 'DASHBOARD_WIDGET_DATA';
export const IS_MOBILE = 'IS_MOBILE';

// Public Page
export const GET_FOOTER_LINK = 'GET_FOOTER_LINK';
export const GET_PAGE_DATA = 'GET_PAGE_DATA';
export const GET_EXTRANET_ACCESS = 'GET_EXTRANET_ACCESS';
export const UPDATE_USER_ROLES = 'UPDATE_USER_ROLES';
// dashboard config Actions
export const GET_DASHBOARD_CONFIG = 'GET_DASHBOARD_CONFIG';
export const CREATE_DASHBOARD_CONFIG = 'CREATE_DASHBOARD_CONFIG';
export const DELETE_DASHBOARD_CONFIG = 'DELETE_DASHBOARD_CONFIG';
export const UPDATE_DASHBOARD_CONFIG = 'UPDATE_DASHBOARD_CONFIG';

// dashboards Actions
export const GET_DASHBOARDS = 'GET_DASHBOARDS';

// auth actions
export const LOGIN = 'LOGIN';
export const SET_USER_DATA = 'SET_USER_DATA';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const LOGOUT = 'LOGOUT';
export const AUTH_VERIFICATION = 'AUTH_VERIFICATION';
// auth actions ends

// repair It Action
export const REPAIR_IT_CHECK = 'REPAIR_IT_CHECK';
export const REPAIR_IT_RECORD = 'REPAIR_IT_RECORD';
export const REPAIR_IT_EXPORT = 'REPAIR_IT_EXPORT';

// Card Layer Action
export const CARD_LAYER_OPEN = 'CARD_LAYER_OPEN';
export const CARD_LAYER_WIDTH = 'CARD_LAYER_WIDTH';
export const CARD_LAYER_DATA = 'CARD_LAYER_DATA';

// Nortification Layer Action
export const NORTIFICATION_LAYER_OPEN = 'NORTIFICATION_LAYER_OPEN';
export const NORTIFICATION_LAYER_WIDTH = 'NORTIFICATION_LAYER_WIDTH';
export const NORTIFICATION_LAYER_DATA = 'NORTIFICATION_LAYER_DATA';
export const MARK_READ = 'MARK_READ';
export const MARK_ALL_READ = 'MARK_ALL_READ';
//Details Layer Actions
export const DETAILS_LAYER_ACTION = 'DETAILS_LAYER_ACTION';
export const DETAILS_LAYER_WIDTH = 'DETAILS_LAYER_WIDTH';
export const DETAILS_LAYER_DATA = 'DETAILS_LAYER_DATA';
export const FORM_LAYER_ACTION = 'FORM_LAYER_ACTION';
export const FORM_LAYER_WIDTH = 'FORM_LAYER_WIDTH';
export const POST_DETAIL_LAYER_ACTION = 'POST_DETAIL_LAYER_ACTION';
export const POST_DETAIL_LAYER_WIDTH = 'POST_DETAIL_LAYER_WIDTH';
export const POST_DETAIL_LAYER_DATA = 'POST_DETAIL_LAYER_DATA';
export const CLEAR_DATA = 'CLEAR_DATA';

export const GET_LINK_WIDGET = 'GET_LINK_WIDGET';
export const GET_MARKETING_WIDGET = 'GET_MARKETING_WIDGET';
export const GET_NORTIFICATIONS = 'GET_NORTIFICATIONS';
export const GET_NORTIFICATIONS_NEXT = 'GET_NORTIFICATIONS_NEXT';

export const SEARCH_KEYWORD = 'SEARCH_KEYWORD';
export const SEARCH_KEYWORD_WIDGETS = 'SEARCH_KEYWORD_WIDGETS';

// Profile Page
export const PROFILE_PAGE = 'PROFILE_PAGE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const ON_BOARDING_COMPLETE = 'ON_BOARDING_COMPLETE';
export const GET_USER_PPROFILE = 'GET_USER_PPROFILE';

// Editor
export const EDITOR_STEP = 'EDITOR_STEP';
export const EDITOR_OPEN = 'EDITOR_OPEN';
export const PREVIEW_STEP = 'PREVIEW_STEP';
export const PREVIEW_OPEN = 'PREVIEW_OPEN';
export const CREATE_NEWS_POST = 'CREATE_NEWS_POST';
export const CREATE_MARKETING_POST = 'CREATE_MARKETING_POST';
export const CREATE_CONTENT_POST = 'CREATE_CONTENT_POST';
export const CREATE_PAGE_BUILDER_POST = 'CREATE_PAGE_BUILDER_POST';
export const GET_ROLES = 'GET_ROLES';
export const GET_INFO_LIST = 'GET_INFO_LIST';
export const GET_CONTACT_DEPARTMENTS = 'GET_CONTACT_DEPARTMENTS';
export const GET_TAG_LIST = 'GET_TAG_LIST';
export const GET_MARKET_LIST = 'GET_MARKET_LIST';
export const GET_EDITORS_LIST = 'GET_EDITORS_LIST';
export const CLONE_CONTENT_POST = 'CLONE_CONTENT_POST';
export const CLONE_NEWS_POST = 'CLONE_NEWS_POST';
export const CLONE_MARKETING_POST = 'CLONE_MARKETING_POST';
export const REMOVE_CLONE_POST = 'REMOVE_CLONE_POST';
export const EDIT_CONTENT_POST = 'EDIT_CONTENT_POST';
export const EDIT_NEWS_POST = 'EDIT_NEWS_POST';
export const EDIT_MARKETING_POST = 'EDIT_MARKETING_POST';
export const UPDATE_MARKETING_POST = 'UPDATE_MARKETING_POST';
export const UPDATE_NEWS_POST = 'UPDATE_NEWS_POST';
export const UPDATE_CONTENT_POST = 'UPDATE_CONTENT_POST';
export const DELETE_NEWS_POST = 'DELETE_NEWS_POST';
export const DELETE_MARKETING_POST = 'DELETE_MARKETING_POST';
export const DELETE_CONTENT_POST = 'DELETE_CONTENT_POST';
export const TOGGLE_POST = 'TOGGLE_POST';
export const GET_ATTRIBUTE_LIST = 'GET_ATTRIBUTE_LIST';
export const GET_USER_REPORTING_STATUS = 'GET_USER_REPORTING_STATUS';
export const USER_REPORTING = 'USER_REPORTING';

// News
export const GET_NEWS_LIST = 'GET_NEWS_LIST';
export const GET_NEWS_BY_ID = 'GET_NEWS_BY_ID';
export const CLEAR = 'CLEAR';

// Newsletter
export const GET_NEWSLETTER_SETTINGS = 'GET_NEWSLETTER_SETTINGS';
export const UPDATE_NEWSLETTER_SETTINGS = 'UPDATE_NEWSLETTER_SETTINGS';

// Marketing
export const GET_MARKETING_POST_BY_ID = 'GET_MARKETING_POST_BY_ID';

// Content
export const GET_CONTENT_POST_BY_ID = 'GET_CONTENT_POST_BY_ID';

// topic
export const SELECTED_TOPIC = 'SELECTED_TOPIC';
export const SET_SELECTED_INFO_TYPE_TAB = 'SET_SELECTED_INFO_TYPE_TAB';
export const GET_TOPIC_LIST = 'GET_TOPIC_LIST';
export const GET_TOPIC_LIST_FOR_ADMIN = 'GET_TOPIC_LIST_FOR_ADMIN';
export const GET_CONTENT_LIST = 'GET_CONTENT_LIST';
export const GET_TOPIC_CONTENT_LIST_NEXT_PAGE = 'GET_TOPIC_CONTENT_LIST_NEXT_PAGE';
export const GET_TOPIC_CONTENT_LIST = 'GET_TOPIC_CONTENT_LIST';
export const GET_TOPIC_INFOTYPE = 'GET_TOPIC_INFOTYPE';
export const GET_CONTENT_DETAIL_BYID = 'GET_CONTENT_DETAIL_BYID';
export const GET_NEWS_DETAIL_BYID = 'GET_NEWS_DETAIL_BYID';
export const GET_TOPIC_CONTENT_LIST_BY_INFOTYPE = 'GET_TOPIC_CONTENT_LIST_BY_INFOTYPE';
export const GET_TOPIC_CONTENT_LIST_BY_INFOTYPE_NEXT_PAGE = 'GET_TOPIC_CONTENT_LIST_BY_INFOTYPE_NEXT_PAGE';
export const GET_CONTENT_LIST_NEXT_PAGE = 'GET_CONTENT_LIST_NEXT_PAGE';
export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS';
export const ADD_TOPIC_FAVORITE = 'ADD_TOPIC_FAVORITE';
export const DELETE_TOPIC_FAVORITE = 'DELETE_TOPIC_FAVORITE';
export const SET_ORDER_OPTIONS = 'SET_ORDER_OPTIONS';
export const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND';
export const GET_TOPIC_LIST_BY_BRAND = 'GET_TOPIC_LIST_BY_BRAND';
export const SET_TOPIC_CONTENT_LIST = 'SET_TOPIC_CONTENT_LIST';

// admin topics
export const ADMIN_TOPICS_EDITOR_OPEN = 'ADMIN_TOPICS_EDITOR_OPEN';
export const ADMIN_TOPICS_EDITOR_TOPIC = 'ADMIN_TOPICS_EDITOR_TOPIC';
export const ADMIN_TOPICS_DELETE_TOPIC = 'ADMIN_TOPICS_DELETE_TOPIC';
export const SEARCH_TOPIC_CLAUSE = 'SEARCH_TOPIC_CLAUSE';
export const DELETE_TOPIC_CLAUSE = 'DELETE_TOPIC_CLAUSE';
export const FILTER_TOPIC = 'FILTER_TOPIC';
export const RESET_FILTER_TOPIC = 'RESET_FILTER_TOPIC';
export const CREATE_TOPIC = 'CREATE_TOPIC';
export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const CHECK_TOPIC_NAME = 'CHECK_TOPIC_NAME';

//Bottom Nav Action
export const HELP_BUTTON_ACTION = 'HELP_BUTTON_ACTION';
export const PORTALS_BUTTON_ACTION = 'PORTALS_BUTTON_ACTION';

export const SEARCH_PARAMS_ACTION = 'SEARCH_PARAMS_ACTION';
export const SEARCH_OPTIONS = 'SEARCH_OPTIONS';
export const DELETE_SEARCH_OPTIONS = 'DELETE_SEARCH_OPTIONS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CLEAR_CURSOR = 'CLEAR_CURSOR';
export const NEXT_PAGE_RESULT = 'NEXT_PAGE_RESULT';

export const FILETR_SEARCH_OPTIONS = 'FILETR_SEARCH_OPTIONS';
export const FILETR_DELETE_SEARCH_OPTIONS = 'FILETR_DELETE_SEARCH_OPTIONS';
export const FILETR_CLEAR_FILTERS = 'FILETR_CLEAR_FILTERS';

//
export const GET_CANDIDATES_LIST = 'GET_CANDIDATES_LIST';
export const UPDATE_CANDIDATES = 'UPDATE_CANDIDATES';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const NEXT_CANDIDATES_LIST = 'NEXT_CANDIDATES_LIST';
export const GET_USER_ROLES_BY_LANG = 'GET_USER_ROLES_BY_LANG';
export const FILTER_MARKET_CANDIDATE = 'FILTER_MARKET_CANDIDATE';
export const FILTER_USER_GROUP = 'FILTER_USER_GROUP';

export const GET_USER_LIST = 'GET_USER_LIST';
export const NEXT_USER_LIST = 'NEXT_USER_LIST';
export const SEARCH_USER_CLAUSE = 'SEARCH_USER_CLAUSE';
export const DELETE_USER_CLAUSE = 'DELETE_USER_CLAUSE';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const CHANGE_USER_LIST_PAYLOAD = 'CHANGE_USER_LIST_PAYLOAD';
